import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

const error404 = () => (
	<Layout errorPath={true}>
		<SEO title="404: Not found" />
		<h1>Not Found</h1>
	</Layout>
);

export default error404;
